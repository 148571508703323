import moment from 'moment';
import PdfUtils from "./pdfUtils";
const footer = require("@/assets/images/footerReport.png");
const logo = require("@/assets/images/logoRed.png");

function printPage(receiptItem) {
    const pdf = new PdfUtils();
    const doc = pdf.getDoc();

    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;

    pdf.setFontSize(10);
    pdf.addImage(logo, "PNG", 2, 2, 57, 30);

    let Y = 10;
    pdf.setFont('bold').drawText("RECIBO DE CARREGAMENTO", 136, Y).setFont('normal');
    pdf.printNormalAndBold("**Sollus** Indústria Eletrônica Ltda.", 60, Y);

    Y += 5;
    pdf.drawText("CNPJ/MF: 22.108.794/0001-44", 60, Y);
    Y += 10;
    pdf.drawText("SAC: +55 (35) 3473-0824", 60, Y);

    const X = 45;
    Y = 80;
    const BOX_H = 16;
    const BOX_W = 60;

    pdf.drawBox({
        title: `${receiptItem.charger}`,
        subtitle: "CARREGADOR",
        x: X,
        y: Y,
        width: BOX_W,
        height: BOX_H,
    });

    pdf.drawBox({
        title: `${moment(receiptItem.dateFinish).format('DD/MM/YYYY HH:mm:ss')}`,
        subtitle: "Data",
        x: X + BOX_W,
        y: Y,
        width: BOX_W,
        height: BOX_H,
    });

    pdf.drawBox({
        title: `R$ ${receiptItem.value ? Number.parseFloat(receiptItem.value).toFixed(2) : 0}`,
        subtitle: "Valor Total",
        x: X,
        y: Y + BOX_H,
        width: BOX_W,
        height: BOX_H,
    });

    pdf.drawBox({
        title: `${receiptItem.consumption ? receiptItem.consumption : 0} kWh`,
        subtitle: "Consumo",
        x: X + BOX_W,
        y: Y + BOX_H,
        width: BOX_W,
        height: BOX_H,
    });

    pdf.addImage(footer, "PNG", 0, pageHeight - 125, pageWidth, 125);

    return doc;
}

export default {
    create: (receiptItem) => {
        return printPage(receiptItem);
    },
};